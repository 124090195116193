<template>
   <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenu></sideMenu>
              </div>
              <div class="col-lg-9">

                <div>
                  <h4 class="comm-title">Arrived Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else class="row">
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                   <router-link
                                    :to="{
                                      name: 'AuctionDetails',
                                      params: {
                                        url_key: VehicleDetails.aucName,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  />
                                  </router-link>
<!-- 
                                  <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div> -->
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                    :to="{
                                      name: 'AuctionDetails',
                                      params: {
                                        url_key: VehicleDetails.aucName,
                                      },
                                    }"
                                  >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>
                                    
                                    <div class="row">
                                      <div class="col-6">
                                        <div class="text-center">
                                          <div>
                                            <!-- TODO need warning  for delivery confirmation  -->
                                            <button
                                              class="bid-info-sub1"
                                              tabindex="-1"
                                              @click="
                                                showaAlert(VehicleDetails)
                                              "
                                            >
                                              Confirm Delivery
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div class="text-center">
                                          
                                        </div>
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="openConfirmModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openConfirmModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>
                  <p>
                    Do you really want to confirm delivery? This process cannot
                    be undone.
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openConfirmModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button class="bid-info-sub" @click="confirmDelivery()">
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenu from "@/components/sideMenu.vue";
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  components: {
    sideMenu,
    PageNation,
    NotFound,
    navbar,
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      auctionEdit: "",
      message: "",
      openConfirmModel: false,
      navbar,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));

    
      if (typeof this.userinfo.customerId != "undefined") {
        this.getArrivedVehList(this.userinfo.customerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    SubmitBid() {
      this.message = "Success";
      var self = this;
      setTimeout(function () {
        self.closeModel();
      }, 2000);
    },
    closeModel() {
      this.message = "";
      this.openConfirmModel = false;
      location.reload();
    },
    showaAlert(e) {

      
      this.auctionEdit = e; 
      this.openConfirmModel = !this.openConfirmModel;
    },
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },

    confirmDelivery() {
      userService
        .updAuctionStatus(this.auctionEdit, "Delivered", "Customer")
        .then((response) => {
          this.message = response.data.Message;
          if (this.message == "Success") {
            this.message ="";
            this.$toast.success("Added to  delivered ", {
              position: "top",
              duration: 3000,
              queue: true
            });
            var self = this;
            setTimeout(function () {
              self.closeModel();
            }, 3000);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
            queue: true
          });
        });
    },
    getArrivedVehList(custId) {
      let id = custId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getCustomerWonList(id, "Arrived")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.overlay-bid-btn {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(226 240 248);
  position: fixed;
  width: 30%;
  margin: 150px 35%;
  border-radius: 5px;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* modal css */
.modal-confirm {
  color: #636363;
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 2 6px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.modalconfirm {
  position: fixed;
  top: 20%;
  left: 40%;
  z-index: 9999999;
}
.bid-info-sub1
{
      background-color: #648ED1;
    padding: 5px 5px;
    color: white!important;
    border-radius: 5px;
    width: 135px;
    display: block;
    font-weight: 600;
    margin: 0px auto;
}
</style>
